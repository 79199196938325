import React, { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useLocation } from "react-router";
import { Snackbar } from "./components/Snackbar";
import { useNavigatorOnline } from "./hooks/useNavigatorOnline";
import {
  Notification,
  NotificationType,
  useNotifications,
} from "./hooks/useNotifications";
import { useTracking } from "./hooks/useTracking";
import * as serviceWorker from "./serviceWorkerRegistration";

type Language = "en" | "hu";

interface IAppContext {
  // appParams: AppParams;
  // setAppParams: (appParams: AppParams) => void;
  // removeAppParams: () => void;
  ref: string;
  deviceId: string;
  sessionId: string;
  isUpdateAvailable: boolean;
  isOnline: boolean;
  language: Language;
  refreshApp: () => void;
  setLanguage?: (language: Language) => void;
  notifications?: Notification[];
  addNotification?: (
    title: string,
    message: string,
    type?: NotificationType
  ) => string;
  removeNotification?: (id: string) => void;
  removeNotificationsOfType?: (type: NotificationType) => void;
}

export const AppContext = React.createContext<IAppContext>({
  ref: "",
  deviceId: "",
  sessionId: "",
  isUpdateAvailable: false,
  isOnline: true,
  language: "en",
  refreshApp: () => null,
});

AppContext.displayName = "AppContext";

interface AppContextProviderProps {
  children: React.ReactNode;
}

export const AppContextProvider: React.FC<AppContextProviderProps> = ({
  children,
}) => {
  // const [appParams, setAppParams, removeAppParams] =
  //   useEncodedQueryParam<AppParams>("p", {});

  const { deviceId, sessionId, ref } = useTracking();
  const { isOnline } = useNavigatorOnline();

  const { pathname } = useLocation();

  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
  const [waitingServiceWorker, setWaitingServiceWorker] =
    useState<ServiceWorker | null>(null);
  const [language, setLanguage] = useState<Language>("en");
  const {
    notifications,
    addNotification,
    removeNotification,
    removeNotificationsOfType,
  } = useNotifications();

  useEffect(() => {
    if (isUpdateAvailable && pathname === "/") {
      skipWaiting(waitingServiceWorker);
    }
  }, [isUpdateAvailable, waitingServiceWorker, pathname]);

  const handleUpdateAvailable = useCallback(
    (registration: ServiceWorkerRegistration) => {
      setIsUpdateAvailable(true);
      setWaitingServiceWorker(registration.waiting);
    },
    []
  );

  useEffect(() => {
    serviceWorker.register({
      onUpdateAvailable: handleUpdateAvailable,
    });
  }, [handleUpdateAvailable]);

  const skipWaiting = (waitingServiceWorker?: ServiceWorker | null) => {
    setIsUpdateAvailable(false);
    waitingServiceWorker?.postMessage({ type: "SKIP_WAITING" });
  };

  const contextValue: IAppContext = {
    deviceId,
    sessionId,
    // setAppParams,
    // removeAppParams,
    // appParams,
    ref,
    isUpdateAvailable,
    isOnline,
    language,
    refreshApp: skipWaiting,
    setLanguage,
    notifications,
    addNotification,
    removeNotification,
    removeNotificationsOfType,
  };

  const notificationsContainer = document.getElementById("notifications")!;

  return (
    <AppContext.Provider value={contextValue}>
      {children}
      {createPortal(
        notifications.map((n) => (
          <Snackbar
            key={n.id}
            id={n.id}
            isActive={true}
            message={n.message}
            title={n.title}
            onCloseClick={() => removeNotification(n.id)}
          />
        )),
        notificationsContainer
      )}
    </AppContext.Provider>
  );
};
