import React from "react";
import { Link } from "react-router-dom";
import { useNotifications } from "../../hooks/useNotifications";
import { LanguageSelector } from "../LanguageSelector";
import { Logo } from "../Logo";
import styles from "./Header.module.css";

export const Header = () => {
  const { notifications } = useNotifications();

  return (
    <header className={styles.header}>
      <Link to="/">
        <div className={styles.lockup}>
          <div className={styles.logoContainer}>
            <Logo />
          </div>
          <h1 className={styles.heading}>Centropolitan</h1>
        </div>
      </Link>
      <div className={styles.toolbar}>
        <LanguageSelector />
        {!!notifications.length ? (
          <Link to="/notifications">
            <div className={styles.notificationsIndicator}>
              {notifications.length}
            </div>
          </Link>
        ) : (
          <></>
        )}
      </div>
    </header>
  );
};
