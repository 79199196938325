import {
  apartments,
  DayType,
  swappedRestdays,
  swappedWorkdays,
  nationalHolidays,
} from "../constants";

export const isLocalhost = (): boolean =>
  Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

export const isProd = () =>
  window.isProd && window.location.hostname === "centropolitan.cordia.homes";

export const getDateString = (date: Date): string =>
  `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;

export const getDayType = (date: Date): DayType => {
  const dateString = getDateString(date);

  // Exceptions
  if (swappedWorkdays.includes(dateString)) return DayType.SWAPPED_WORKDAY;
  if (swappedRestdays.includes(dateString)) return DayType.SWAPPED_RESTDAY;
  if (nationalHolidays.includes(dateString)) return DayType.NATIONAL_HOLIDAY;

  // Ordinary Saturdays
  if (date.getDay() === 6) return DayType.SATURDAY;

  // Ordinary Sundays
  if (date.getDay() === 0) return DayType.SUNDAY;

  // Ordinary weekdays
  return DayType.WEEKDAY;
};

export const isNoiseAllowed = (date: Date): boolean => {
  const dayType = getDayType(date);

  switch (dayType) {
    case DayType.SUNDAY:
    case DayType.NATIONAL_HOLIDAY:
      return false;
    case DayType.SATURDAY:
    case DayType.SWAPPED_RESTDAY:
      return date.getHours() >= 9 && date.getHours() < 13;
    case DayType.WEEKDAY:
    case DayType.SWAPPED_WORKDAY:
      return date.getHours() >= 9 && date.getHours() < 18;
    default:
      return true;
  }
};

export const nextNoisePolicyChange = (date: Date): Date => {
  const isAllowed = isNoiseAllowed(date);

  // Go back to the beginning of this hour.
  date.setTime(date.getTime() - (date.getTime() % (60 * 60 * 1000)));

  do {
    // Go to the beginning of the next hour.
    date.setTime(date.getTime() + 60 * 60 * 1000);
  } while (isAllowed === isNoiseAllowed(date));

  return date;
};

export const getUTCISODateString = (date: Date): string =>
  date.toISOString().substring(0, 10);

export const isValidApartmentNumber = (value: string) =>
  apartments.includes(value);

export const getOnlineStatus = () =>
  typeof navigator !== "undefined" && typeof navigator.onLine === "boolean"
    ? navigator.onLine
    : true;

export const isToday = (date: Date): boolean =>
  getDateString(date) === getDateString(new Date());
