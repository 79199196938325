import { collection, doc, setDoc } from "firebase/firestore";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { firestore } from "../firebaseApp";
import { EventDocument } from "../types";
import { v4 as uuidv4 } from "uuid";
import { getOnlineStatus, isLocalhost, isProd } from "../utils";
import { useTracking } from "./useTracking";

export const useEventRegistration = () => {
  const { deviceId, sessionId, ref } = useTracking();
  const { i18n } = useTranslation();

  const migrateEvent = (eventDoc: any) => {
    const id = eventDoc.id || uuidv4();
    const docName = `${eventDoc.timestamp
      .toDate()
      .toISOString()}_${eventDoc.deviceId.substring(
      0,
      8
    )}_${eventDoc.sessionId.substring(0, 8)}_${id.substring(0, 8)}`;

    const eventsCollection = collection(
      firestore,
      `devices/${eventDoc.deviceId.substring(0, 8)}/events/`
    );

    const newDoc: EventDocument = {
      ...eventDoc,
      deviceId: eventDoc.deviceId.substring(0, 8),
      sessionId: eventDoc.sessionId.substring(0, 8),
      id,
      timestamp: eventDoc.timestamp.toDate(),
    };

    console.log(eventDoc);
    console.log(newDoc);

    setDoc(doc(eventsCollection, docName), newDoc);
  };

  const registerEvent = useCallback(
    (event: string, details: boolean | number | string | Date = "") => {
      // if (isLocalhost()) return;

      try {
        const now = new Date();
        const eventId = uuidv4();
        const docName = `${now.toISOString()}_${deviceId.substring(
          0,
          8
        )}_${sessionId.substring(0, 8)}_${eventId.substring(0, 8)}`;

        const eventsCollection = collection(
          firestore,
          `${isProd() ? "devices" : "test_devices"}/${deviceId.substring(
            0,
            8
          )}/events/`
        );

        const eventDocument: EventDocument = {
          id: eventId,
          deviceId: deviceId.substring(0, 8),
          sessionId: sessionId.substring(0, 8),
          appLanguage: i18n.language || "",
          appVersion: window.version || "",
          navigatorData: {
            languages: JSON.stringify(navigator?.languages),
            isOnline: getOnlineStatus(),
            userAgent: navigator?.userAgent || "",
          },
          timestamp: now,
          location: window.location.href,
          ref,
          event,
          details,
        };

        if (isLocalhost()) {
          console.log("Registering event:", eventDocument);
        }

        // async
        return setDoc(doc(eventsCollection, docName), eventDocument);
      } catch (e) {
        console.log("An error occurred when registering an event.", e);
      }
    },
    [deviceId, sessionId, ref, i18n.language]
  );

  return { registerEvent, migrateEvent };
};
