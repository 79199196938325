import React from "react";
import { useTranslation } from "react-i18next";
import { isLocalhost } from "../../utils";
import { LinkButton } from "../LinkButton";
import styles from "./Navigation.module.css";

export const Navigation = () => {
  const { t } = useTranslation();

  return (
    <nav>
      <ul className={styles.navigation}>
        {/* <li>
          <LinkButton path="/">Home</LinkButton>
        </li> */}
        <li>
          <LinkButton to="noise">{t("canIMakeNoiseNow")}</LinkButton>
        </li>
        <li>
          <LinkButton to="community">{t("community")}</LinkButton>
        </li>
        <li>
          <LinkButton to="instagram">{t("instagram")}</LinkButton>
        </li>
        <li>
          <LinkButton to="about">{t("about")}</LinkButton>
        </li>
        <li>
          <LinkButton disabled to="" label={t("soonLabel")}>
            {t("houseRules")}
          </LinkButton>
        </li>
        <li>
          <LinkButton disabled to="" label={t("soonLabel")}>
            {t("defectReport")}
          </LinkButton>
        </li>
        <li>
          <LinkButton disabled to="" label={t("soonLabel")}>
            {t("emergency")}
          </LinkButton>
        </li>
        {isLocalhost() ? (
          <li>
            <LinkButton to="admin">Admin</LinkButton>
          </li>
        ) : (
          <></>
        )}
      </ul>
    </nav>
  );
};
