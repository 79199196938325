import { useEffect, useState } from "react";
import { isNoiseAllowed, nextNoisePolicyChange } from "../utils";

export const useNoise = () => {
  const [nextChange, setNextChange] = useState<Date>(
    nextNoisePolicyChange(new Date())
  );

  const [isAllowed, setIsAllowed] = useState<boolean>(
    isNoiseAllowed(new Date())
  );

  const [refreshTimeout, setRefreshTimeout] = useState<NodeJS.Timeout>();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setNextChange(nextNoisePolicyChange(new Date()));
      setIsAllowed(isNoiseAllowed(new Date()));
    }, nextChange.getTime() - new Date().getTime() + 1000);

    setRefreshTimeout(timeout);

    return () => {
      refreshTimeout && clearTimeout(refreshTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextChange.toISOString()]);

  return {
    isAllowed,
    nextChange,
  };
};
