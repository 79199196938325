import { AppParams } from "../types";
import { useDeviceId } from "./useDeviceId";
import { useEncodedQueryParam } from "./useEncodedQueryParam";
import { useSessionId } from "./useSessionId";

export const useTracking = () => {
  const { deviceId } = useDeviceId();
  const { sessionId } = useSessionId();
  const [appParams] = useEncodedQueryParam<AppParams>("p", {});

  return { deviceId, sessionId, ref: appParams.ref || "" };
};
