export const translations = {
  en: {
    translation: {
      community: "Community",
      previousStep: "Previous step",
      startOver: "Start over",
      weHaveAFacebookGroup:
        "We have a Facebook Group dedicated to Centropolitan residents and property owners.",
      joinTheCommunityToConnectWithNeighbors:
        "Join the community today to connect with your neighbors, and to stay informed about the house.",
      beforeYouJoinSurvey:
        "Before you join, we'd like to get to know you a bit. It only takes 30 seconds!",
      letsGetStarted: "Let's get started",
      whatsYourFirstName: "What's your first name?",
      doYouLiveInTheBuilding: "Do you live in the building?",
      yes: "Yes",
      no: "No",
      noIdontHave: "No",
      doYouOwnApartmentInTheBuilding:
        "Do you own an apartment in the building?",
      facebookGroup: "Facebook Group",
      joinFacebookGroup: "Join Facebook Group",
      ifYouChangeYourMindYoureAlwaysWelcomeToJoin:
        "If you change your mind, you're always welcome to join.",
      toJoinTheGroupClick:
        "To join the group, click the button below. Answering all membership questions will get you approved sooner.",
      visitFacebookGroup: "Visit Facebook Group",
      itsGreatToHaveYou: "It's great to have you.",
      iOwnMultipleApartments: "I own multiple apartments.",
      areYouAlreadyInFacebookGroup: "Are you already in the Facebook Group?",
      theFacebookGroupIsForResidentsOnly:
        "The Facebook Group is exclusively for people who live here or own property in the building.",
      inWhichApartmentDoYouLive: "In which apartment do you live?",
      whichApartmentsDoYouOwn: "Which apartment(s) do you own?",
      doYouUseParkingPlace: "Do you own or rent a parking place in the garage?",
      wouldYouLikeToJoin: "Would you like to join?",
      home: "Home",
      soonLabel: "Soon!",
      canIMakeNoiseNow: "Can I make noise now?",
      noiseAllowed: "You're allowed to perform noisy activities until",
      noiseProhibited:
        "Shhh! 🤫 Please refrain from making noise in the building, as it's strictly prohibited until",
      noiseUntilPostfix: ".",
      noiseReferToHouseRules:
        "Please refer to the House Rules for the exact policy on noise, which you can find on the bulletin board in the lobby.",
      about: "About",
      forExample: "For example:",
      aboutThisApp: "About this app",
      disclaimer: "Disclaimer",
      disclaimerText:
        "This app was built by a resident of the Centropolitan building, only for fun, as a hobby project. Please note that the app and its developer are not associated with Cordia or Cordia FM in any way. The developer tries their best to provide reliable information, however, they take no responsibility for it. For reliable information about the condominium, please contact Cordia FM via the official channels. This application cannot be used to call for help in case of emergency. In case of an emergency, please contact the local emergency services.",
      technicalDetails: "Technical Details",
      comingSoon: "Coming soon.",
      contact: "Contact",
      clickHereToContactDev: "Click here to contact the developer.",
      houseRules: "House Rules",
      defectReport: "Defect Report",
      emergency: "Emergency",
      todayIsNationalHoliday: "Today is a national holiday.",
      todayIsSwappedRestday: "Today is a swapped restday.",
      todayIsSwappedWorkday: "Today is a swapped workday.",
      instagram: "Instagram",
      redirectingToInstagram: "Redirecting to Instagram...",
      rules: {
        paragraph1: "",
      },
    },
  },
  hu: {
    translation: {
      community: "Közösség",
      previousStep: "Előző lépés",
      startOver: "Újrakezdés",
      weHaveAFacebookGroup:
        "A háznak van egy Facebook-csoportja, ami kizárólag a lakók és a tulajdonosok számára van fenntartva.",
      joinTheCommunityToConnectWithNeighbors:
        "Csatlakozz a csoporthoz még ma, hogy kapcsolatban maradj szomszédaiddal, és hogy naprakész légy a ház ügyeivel kapcsolatban.",
      beforeYouJoinSurvey:
        "Mielőtt csatlakozol, szeretnénk egy kicsit megismerni. Csak fél perc az egész!",
      letsGetStarted: "Kezdjük",
      whatsYourFirstName: "Mi a keresztneved?",
      doYouLiveInTheBuilding: "Az épületben laksz?",
      forExample: "Például:",
      yes: "Igen",
      no: "Nem",
      noIdontHave: "Nincs",
      doYouOwnApartmentInTheBuilding: "Van lakástulajdonod az épületben?",
      joinFacebookGroup: "Csatlakozom a Facebook-csoporthoz",
      ifYouChangeYourMindYoureAlwaysWelcomeToJoin:
        "Ha meggondolod magad, bármikor szívesen látunk.",
      toJoinTheGroupClick:
        "A csatlakozáshoz kattints az alábbi gombra. A belépéshez szükséges összes kérdés megválaszolása gyorsíthatja a folyamatot.",
      visitFacebookGroup: "Ugrás a Facebook-csoportba",
      itsGreatToHaveYou: "Jó, hogy itt vagy.",
      iOwnMultipleApartments: "Több lakásom is van.",
      areYouAlreadyInFacebookGroup: "Benne vagy már a Facebook-csoportban?",
      theFacebookGroupIsForResidentsOnly:
        "A Facebook-csoport kizárólag az épület lakóinak és tulajdonosainak van fenntartva.",
      inWhichApartmentDoYouLive: "Melyik lakásban laksz?",
      whichApartmentsDoYouOwn: "Melyik lakás(ok) tartoznak hozzád?",
      doYouUseParkingPlace: "Van saját vagy bérelt parkolód a garázsban?",
      wouldYouLikeToJoin: "Szeretnél csatlakozni?",
      home: "Kezdőlap",
      soonLabel: "Jön!",
      canIMakeNoiseNow: "Zajonghatok most?",
      noiseAllowed: "Most éppen végezhetsz zajjal járó tevékenységet,",
      noiseProhibited:
        "Pssszt! 🤫 Kérünk, most ne végezz zajjal járó tevékenységet az épületben, mert az szigorúan tilos",
      noiseUntilPostfix: " óráig.",
      noiseReferToHouseRules:
        "Az erről szóló pontos szabályozást megtalálod a földszinti üzenőfalon kifüggesztett Házirendben.",
      facebookGroup: "Facebook-csoport",
      about: "Az alkalmazásról",
      aboutThisApp: "Az alkalmazásról",
      disclaimer: "Jogi felelősségkizárás",
      disclaimerText:
        "Az alkalmazás a Centropolitan épület egyik lakójának hobbiprojektje, amelyet pusztán kedvtelésből készített. Az alkalmazás és annak fejlesztője semmilyen kapcsolatban nem áll a Cordiával és a Cordia FM-mel. Bár a fejlesztő igyekszik a lehető legmegbízhatóbb információt közzétenni az alkalmazásban, annak hitelességéért nem vállal felelősséget. Az épülettel kapcsolatos megbízható információért vedd fel a kapcsolatot a Cordia FM-mel a hivatalos csatornákon keresztül. Az alkalmazás hivatalosan vészhelyzeti segélyhívásra nem használható. Vészhelyzetben értesítsd a helyi készenléti szerveket.",
      technicalDetails: "Technikai információk",
      comingSoon: "Hamarosan.",
      contact: "Kapcsolat",
      clickHereToContactDev:
        "A fejlesztővel való kapcsolatfelvételhez kattints ide.",
      houseRules: "Házirend",
      defectReport: "Hibabejelentés",
      emergency: "Vészhelyzet",
      todayIsNationalHoliday: "Ma nemzeti ünnep van.",
      todayIsSwappedRestday: "Ma áthelyezett pihenőnap van.",
      todayIsSwappedWorkday: "Ma áthelyezett munkanap van.",
      instagram: "Instagram",
      redirectingToInstagram: "Átirányítás az Instagramra...",
      rules: {
        paragraph1: "",
      },
    },
  },
};
