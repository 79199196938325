// Import the functions you need from the SDKs you need
import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { partial } from "lodash";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCjJA6yifTPimq4WWyhv4r5s2FKCFhD_H4",
  authDomain: "centropolitan-io.firebaseapp.com",
  projectId: "centropolitan-io",
  storageBucket: "centropolitan-io.appspot.com",
  messagingSenderId: "726617655321",
  appId: "1:726617655321:web:9a6a6d3fa76d26d1290288",
  measurementId: "G-P5Q2EVHB2X",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const logEventToAnalytics = partial(logEvent, analytics);

export const firestore = getFirestore();
