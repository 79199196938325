import { useCallback, useMemo } from "react";
import { NavigateOptions, useSearchParams } from "react-router-dom";
import { Base64 } from "js-base64";

export const useEncodedQueryParam = <T>(
  key: string,
  defaultValue: T
): [
  T,
  (newValue: T, options?: NavigateOptions) => void,
  (options?: NavigateOptions) => void
] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(key) || "";

  const value = useMemo<T>(() => {
    try {
      const decodedParamValue = Base64.decode(paramValue);
      if (!decodedParamValue) {
        // No params specified.
        return defaultValue;
      }

      return JSON.parse(Base64.decode(paramValue));
    } catch (e) {
      console.error(
        `An error occurred when parsing parameter "${key}". Parameter value: "${paramValue}".`
      );
      return defaultValue;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, JSON.stringify(defaultValue), paramValue]);

  const setValue = useCallback(
    (newValue: T, options?: NavigateOptions) => {
      const newSearchParams = new URLSearchParams(searchParams);
      try {
        newSearchParams.set(key, Base64.encode(JSON.stringify(newValue), true));
        setSearchParams(newSearchParams, options);
      } catch (e) {
        console.error(
          `An error occurred when setting query parameter "${key}". Value:`,
          newValue
        );
      }
    },
    [key, searchParams, setSearchParams]
  );

  const removeValue = useCallback(
    (options?: NavigateOptions) => {
      const newSearchParams = new URLSearchParams(searchParams);
      try {
        newSearchParams.delete(key);
        setSearchParams(newSearchParams, options);
      } catch (e) {
        console.error(
          `An error occurred when removing query parameter "${key}".`
        );
      }
    },
    [key, searchParams, setSearchParams]
  );

  return [value, setValue, removeValue];
};
