export const apartments: string[] = [
  "101",
  "102",
  "103",
  "104",
  "105",
  "106",
  "107",
  "108",
  "109",
  "110",
  "111",
  "112",
  "113",
  "114",
  "115",
  "116",
  "117",
  "201",
  "202",
  "203",
  "204",
  "205",
  "206",
  "207",
  "208",
  "209",
  "210",
  "211",
  "212",
  "213",
  "214",
  "215",
  "216",
  "217",
  "218",
  "301",
  "302",
  "303",
  "304",
  "305",
  "306",
  "307",
  "308",
  "309",
  "310",
  "311",
  "312",
  "313",
  "314",
  "315",
  "316",
  "317",
  "318",
  "401",
  "402",
  "403",
  "404",
  "405",
  "406",
  "407",
  "408",
  "409",
  "410",
  "411",
  "412",
  "413",
  "414",
  "415",
  "416",
  "417",
  "418",
  "501",
  "502",
  "503",
  "504",
  "505",
  "506",
  "507",
  "508",
  "509",
  "510",
  "511",
  "512",
  "513",
  "514",
  "515",
  "516",
  "517",
  "518",
  "601",
  "602",
  "603",
  "604",
  "605",
  "606",
  "607",
  "608",
  "609",
  "610",
  "611",
  "612",
  "613",
  "614",
  "615",
  "616",
  "617",
  "618",
  "701",
  "702",
  "703",
  "704",
  "705",
  "706",
  "707",
  "708",
  "709",
  "710",
  "711",
  "712",
  "713",
  "801",
  "802",
  "803",
  "804",
  "805",
  "806",
  "807",
  "808",
  "809",
  "810",
  "811",
  "901",
  "902",
  "903",
  "904",
  "905",
  "906",
  "1001",
];

export const nationalHolidays: string[] = [
  "2023-01-01",
  "2023-03-15",
  "2023-04-07",
  "2023-04-08",
  "2023-04-09",
  "2023-04-10",
  "2023-05-01",
  "2023-05-28",
  "2023-05-29",
  "2023-08-20",
  "2023-10-23",
  "2023-11-01",
  // "2023-12-24", // Counts as a normal Sunday in 2023
  "2023-12-25",
  "2023-12-26",
  "2024-01-01",
];

export const swappedRestdays: string[] = [];

export const swappedWorkdays: string[] = [];

export enum DayType {
  WEEKDAY = "WEEKDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
  SWAPPED_WORKDAY = "SWAPPED_WORKDAY",
  SWAPPED_RESTDAY = "SWAPPED_RESTDAY",
  NATIONAL_HOLIDAY = "NATIONAL_HOLIDAY",
}
