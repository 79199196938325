import classnames from "classnames";
import React from "react";
import { Link, LinkProps } from "react-router-dom";
import styles from "./LinkButton.module.css";

interface LinkButtonProps extends LinkProps {
  external?: boolean;
  disabled?: boolean;
  label?: string;
  highlight?: boolean;
  large?: boolean;
  fullWidth?: boolean;
}

export const LinkButton: React.FC<LinkButtonProps> = ({
  external = false,
  disabled = false,
  highlight = false,
  large = false,
  fullWidth = false,
  label = "",
  to,
  children,
  ...linkButtonProps
}) => {
  const buttonClassNames = classnames({
    [styles.linkButton]: true,
    [styles.disabled]: disabled,
    [styles.highlight]: highlight,
    [styles.large]: large,
    [styles.fullWidth]: fullWidth,
  });

  return external ? (
    <a href={to.toString()} className={buttonClassNames}>
      {children}
    </a>
  ) : (
    <Link to={to || "/"} className={buttonClassNames} {...linkButtonProps}>
      {children}
      {label ? <span className={styles.label}>{label}</span> : <></>}
    </Link>
  );
};
