import { v4 as uuidv4 } from "uuid";
import createPersistedState from "use-persisted-state";

export enum NotificationType {
  NEW_APP_VERSION_AVAILABLE = "NEW_APP_VERSION_AVAILABLE",
  GENERAL = "GENERAL",
}

export interface Notification {
  id: string;
  title: string;
  message: string;
  onClick?: () => void;
  onCloseClick?: () => void;
  type: NotificationType;
}

const useNotificationState =
  createPersistedState<Notification[]>("notifications");

export const useNotifications = () => {
  const [notifications, setNotifications] = useNotificationState([]);

  const addNotification = (
    title: string,
    message: string,
    type: NotificationType = NotificationType.GENERAL,
    onClick?: () => void,
    onCloseClick?: () => void
  ) => {
    const id = uuidv4();
    setNotifications((notifications) => [
      ...notifications,
      {
        id,
        title,
        message,
        type,
        onClick,
        onCloseClick,
      },
    ]);

    return id;
  };

  const removeNotification = (id: string) =>
    setNotifications((notifications) =>
      notifications.filter((n) => n.id !== id)
    );

  const removeNotificationsOfType = (type: NotificationType) =>
    setNotifications((notifications) =>
      notifications.filter((n) => n.type !== type)
    );

  return {
    notifications,
    addNotification,
    removeNotification,
    removeNotificationsOfType,
  };
};
