import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AppContextProvider } from "../../AppContextProvider";
import { About } from "../../pages/About";
import { Admin } from "../../pages/Admin";
import { Community } from "../../pages/Community";
import { Home } from "../../pages/Home";
import { Instagram } from "../../pages/Instagram";
import { Noise } from "../../pages/Noise";
import { Notifications } from "../../pages/Notifications";
import { Layout } from "../Layout";

export const App = () => {
  return (
    <>
      <AppContextProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="noise" element={<Noise />} />
            <Route path="community" element={<Community />} />
            <Route path="instagram" element={<Instagram />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="admin" element={<Admin />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </AppContextProvider>
    </>
  );
};
