import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import React from "react";
import ReactDOM from "react-dom";
import { initReactI18next } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import "the-new-css-reset/css/reset.css";
import { App } from "./components/App";
import "./index.css";
import { translations } from "./translations";
import "./variables.css";

window.version = process.env.REACT_APP_VERSION?.substring(0, 7) || "unknown";
window.isProd = process.env.NODE_ENV === "production";

if ("serviceWorker" in navigator) {
  window.refreshing = false;
  navigator.serviceWorker.addEventListener("controllerchange", () => {
    if (!window.refreshing) {
      window.location.reload();
      window.refreshing = true;
    }
  });
}

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV !== "production",
    resources: translations,
    detection: { caches: ["localStorage"] },
  });

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
