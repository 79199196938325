import createPersistedState from "use-persisted-state";
import { v4 as uuidv4 } from "uuid";

export const useSessionId = () => {
  const useSessionIdState = createPersistedState<string>("sid", sessionStorage);
  const [sessionId, setSessionId] = useSessionIdState("");

  try {
    if (!sessionId) {
      const newSessionId = uuidv4().substring(0, 8);
      // console.log(`New SID generated: ${newSessionId}.`);
      setSessionId(newSessionId);
      // TODO: Report SID.
    } else if (sessionId.length > 8) {
      setSessionId(sessionId.substring(0, 8));
    } // console.log(`SID found: ${sessionId}.`);
  } catch (e) {
    // console.error("SID error");
  }

  return { sessionId };
};
