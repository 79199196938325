import React, { useEffect } from "react";
import { Navigation } from "../../components/Navigation";
import { Page } from "../../components/Page";
import { useEventRegistration } from "../../hooks/useEventRegistration";

export const Home = () => {
  const { registerEvent } = useEventRegistration();

  useEffect(() => {
    registerEvent("Visited Home page");
  }, [registerEvent]);

  return (
    <Page displayNav={false} centered>
      <Navigation />
    </Page>
  );
};
