import { useEffect, useState } from "react";
import { getOnlineStatus } from "../utils";

export const useNavigatorOnline = () => {
  const [isOnline, setIsOnline] = useState(getOnlineStatus());

  const setOnline = () => setIsOnline(true);
  const setOffline = () => setIsOnline(false);

  useEffect(() => {
    window.addEventListener("online", setOnline);
    window.addEventListener("offline", setOffline);

    return () => {
      window.removeEventListener("online", setOnline);
      window.removeEventListener("offline", setOffline);
    };
  }, []);

  return { isOnline };
};
