import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import usePersistedState from "use-persisted-state";
import { LinkButton } from "../../components/LinkButton";
import { Page } from "../../components/Page";
import { logEventToAnalytics } from "../../firebaseApp";
import { useEventRegistration } from "../../hooks/useEventRegistration";
import { isValidApartmentNumber } from "../../utils";
import styles from "./Community.module.css";

enum TrueFalseUnknown {
  FALSE = "false",
  TRUE = "true",
  UNKNOWN = "unknown",
}

interface SurveyAnswers {
  name: string;
  livesHere: TrueFalseUnknown;
  ownsApartment: TrueFalseUnknown;
  apartment: string;
  parksHere: TrueFalseUnknown;
  ownsParkingPlace: TrueFalseUnknown;
  parkingPlace: string;
  alreadyInGroup: TrueFalseUnknown;
  wouldLikeToJoin: TrueFalseUnknown;
}

const defaultAnswers: SurveyAnswers = {
  name: "",
  livesHere: TrueFalseUnknown.UNKNOWN,
  ownsApartment: TrueFalseUnknown.UNKNOWN,
  apartment: "",
  parksHere: TrueFalseUnknown.UNKNOWN,
  ownsParkingPlace: TrueFalseUnknown.UNKNOWN,
  parkingPlace: "",
  alreadyInGroup: TrueFalseUnknown.UNKNOWN,
  wouldLikeToJoin: TrueFalseUnknown.UNKNOWN,
};

const VERSION = 2;

export const Community = () => {
  const usePersistedSurveyVersionState =
    usePersistedState<number>("surveyVersion");
  const usePersistedAnswersState =
    usePersistedState<SurveyAnswers>("surveyAnswers");
  const usePersistedLastAnsweredQuestionsState = usePersistedState<string[]>(
    "surveyLastAnsweredQuestions"
  );
  const usePersistedCurrentStepState =
    usePersistedState<string>("surveyCurrentStep");

  const { t } = useTranslation();
  const { registerEvent } = useEventRegistration();

  const [surveyVersion, setSurveyVersion] =
    usePersistedSurveyVersionState(VERSION);
  const [answers, setAnswers] = usePersistedAnswersState(defaultAnswers);
  const [answeredQuestions, setAnsweredQuestions] =
    usePersistedLastAnsweredQuestionsState([]);
  const [currentStep, setCurrentStep] = usePersistedCurrentStepState("start");

  const [nameInputValue, setNameInputValue] = useState("");
  const [apartmentInputValue, setApartmentInputValue] = useState("");
  const [ownsMultipleApartments, setOwnsMultipleApartments] = useState(false);

  useEffect(() => {
    registerEvent("Visited Community page");
  }, [registerEvent]);

  const answerQuestion = (
    question: string,
    value: TrueFalseUnknown | string
  ) => {
    setAnsweredQuestions((answeredQuestions) => [
      ...answeredQuestions,
      question,
    ]);

    setAnswers((prevAnswers) => {
      const newAnswers = {
        ...prevAnswers,
        [question]: value,
      };
      registerEvent(
        `Answered survey question: ${question}`,
        JSON.stringify(newAnswers)
      );
      return newAnswers;
    });
  };

  const resetWizard = useCallback(() => {
    setSurveyVersion(VERSION);
    setAnswers(defaultAnswers);
    setAnsweredQuestions([]);
    setCurrentStep("start");
    registerEvent("Resetted survey");
  }, [
    setSurveyVersion,
    setAnswers,
    setAnsweredQuestions,
    registerEvent,
    setCurrentStep,
  ]);

  const resetPreviousAnswer = () => {
    if (!answeredQuestions.length) {
      return;
    }

    const lastAnsweredQuestion =
      answeredQuestions[answeredQuestions.length - 1];
    setAnsweredQuestions((answeredQuestions) => answeredQuestions.slice(0, -1));

    setAnswers((prevAnswers) => {
      const newAnswers = {
        ...prevAnswers,
        [lastAnsweredQuestion]:
          defaultAnswers[lastAnsweredQuestion as keyof SurveyAnswers],
      };
      setCurrentStep(lastAnsweredQuestion);
      console.log(newAnswers);
      return newAnswers;
    });
  };

  const handleNameFieldKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === "Enter" &&
      !!nameInputValue.trim() &&
      nameInputValue.trim().length > 1
    ) {
      answerQuestion("name", e.currentTarget.value.trim());
      setCurrentStep("livesHere");
    }
  };

  const handleApartmentFieldKeyUp = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (
      e.key === "Enter" &&
      !!apartmentInputValue.trim() &&
      apartmentInputValue.trim().length > 2
    ) {
      answerQuestion("apartment", e.currentTarget.value.trim());
      setCurrentStep("parksHere");
    }
  };

  const handleStartButtonClick = () => {
    registerEvent("Started survey");
    setAnsweredQuestions(["start"]);
    setCurrentStep("name");
  };

  useEffect(() => {
    if (surveyVersion !== VERSION) {
      registerEvent(
        "Survey was reset because of version mismatch",
        JSON.stringify({
          old: surveyVersion,
          new: VERSION,
        })
      );
      resetWizard();
    }
  }, [registerEvent, surveyVersion, resetWizard]);

  return (
    <Page>
      <h2 className="h2">{t("community")}</h2>
      <hr />
      <div className={styles.actions}>
        <div hidden={currentStep === "start"}>
          <p onClick={() => resetPreviousAnswer()}>&lt; {t("previousStep")}</p>
        </div>
        <div hidden={currentStep === "start"}>
          <p onClick={() => resetWizard()}>{t("startOver")}</p>
        </div>
      </div>

      <article className={styles.survey}>
        <div className={styles.steps}>
          <section className={styles.step} hidden={currentStep !== "start"}>
            <div className="vertical-rhythm-large align-center">
              <p>{t("weHaveAFacebookGroup")}</p>
              <p>{t("joinTheCommunityToConnectWithNeighbors")}</p>
              <p style={{ fontWeight: "800" }}>{t("beforeYouJoinSurvey")}</p>
              <LinkButton
                to="#"
                highlight
                onClick={() => handleStartButtonClick()}
              >
                {t("letsGetStarted")}
              </LinkButton>
            </div>
          </section>

          <section className={styles.step} hidden={currentStep !== "name"}>
            <p className={styles.question}>{t("whatsYourFirstName")}</p>
            <div className={styles.vertical}>
              <input
                type="text"
                value={nameInputValue}
                onChange={(e) => setNameInputValue(e.target.value)}
                onKeyDown={(e) => handleNameFieldKeyUp(e)}
              />
              <button
                onClick={() => {
                  answerQuestion("name", nameInputValue.trim());
                  setCurrentStep("livesHere");
                }}
                disabled={
                  !nameInputValue.trim() || nameInputValue.trim().length < 2
                }
              >
                &gt;&gt;&gt;
              </button>
            </div>
          </section>

          <section className={styles.step} hidden={currentStep !== "livesHere"}>
            <p className={styles.question}>{t("doYouLiveInTheBuilding")}</p>
            <div className={styles.horizontal}>
              <button
                onClick={() => {
                  answerQuestion("livesHere", TrueFalseUnknown.TRUE);
                  setCurrentStep("ownsApartment");
                }}
              >
                {t("yes")}
              </button>
              <button
                onClick={() => {
                  answerQuestion("livesHere", TrueFalseUnknown.FALSE);
                  setCurrentStep("ownsApartment");
                }}
              >
                {t("no")}
              </button>
            </div>
          </section>

          <section
            className={styles.step}
            hidden={currentStep !== "ownsApartment"}
          >
            <p className={styles.question}>
              {t("doYouOwnApartmentInTheBuilding")}
            </p>
            <div className={styles.horizontal}>
              <button
                onClick={() => {
                  answerQuestion("ownsApartment", TrueFalseUnknown.TRUE);
                  setCurrentStep("apartment");
                }}
              >
                {t("yes")}
              </button>
              <button
                onClick={() => {
                  answerQuestion("ownsApartment", TrueFalseUnknown.FALSE);
                  setCurrentStep(
                    answers.livesHere === TrueFalseUnknown.TRUE
                      ? "apartment"
                      : "parksHere"
                  );
                }}
              >
                {t("noIdontHave")}
              </button>
            </div>
          </section>

          <section className={styles.step} hidden={currentStep !== "apartment"}>
            <p className={styles.question}>
              {answers.livesHere === TrueFalseUnknown.TRUE
                ? t("inWhichApartmentDoYouLive")
                : t("whichApartmentsDoYouOwn")}
            </p>
            <div className={styles.vertical}>
              {answers.livesHere === TrueFalseUnknown.FALSE &&
              answers.ownsApartment === TrueFalseUnknown.TRUE ? (
                <div
                  onClick={() =>
                    setOwnsMultipleApartments(!ownsMultipleApartments)
                  }
                  className={styles.checkboxContainer}
                >
                  <span className={styles.checkbox}>
                    {ownsMultipleApartments ? "✓" : ""}
                  </span>
                  <span>{t("iOwnMultipleApartments")}</span>
                </div>
              ) : (
                <></>
              )}
              {answers.livesHere === TrueFalseUnknown.TRUE ||
              !ownsMultipleApartments ? (
                <>
                  <div>
                    <input
                      type="text"
                      pattern="\d*"
                      inputMode="numeric"
                      maxLength={apartmentInputValue.startsWith("100") ? 4 : 3}
                      value={apartmentInputValue}
                      onChange={(e) =>
                        setApartmentInputValue(
                          e.target.value.replace(/[^0-9]/g, "")
                        )
                      }
                      onKeyUp={handleApartmentFieldKeyUp}
                    />
                    <div className={styles.note}>{t("forExample")} 303</div>
                  </div>

                  <button
                    onClick={() => {
                      answerQuestion("apartment", apartmentInputValue.trim());
                      setCurrentStep("parksHere");
                    }}
                    disabled={!isValidApartmentNumber(apartmentInputValue)}
                  >
                    &gt;&gt;&gt;
                  </button>
                </>
              ) : (
                <>
                  <div>
                    <input
                      type="text"
                      value={apartmentInputValue}
                      onChange={(e) =>
                        setApartmentInputValue(
                          e.target.value.replaceAll(/[^0-9,\s]/g, "")
                        )
                      }
                      onKeyUp={handleApartmentFieldKeyUp}
                    />
                    <div className={styles.note}>
                      {t("forExample")} 303, 304, 305
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      answerQuestion("apartment", apartmentInputValue.trim());
                      setCurrentStep("parksHere");
                    }}
                    disabled={
                      !apartmentInputValue || apartmentInputValue.length < 2
                    }
                  >
                    &gt;&gt;&gt;
                  </button>
                </>
              )}
            </div>
          </section>

          <section className={styles.step} hidden={currentStep !== "parksHere"}>
            <p className={styles.question}>{t("doYouUseParkingPlace")}</p>
            <div className={styles.horizontal}>
              <button
                onClick={() => {
                  answerQuestion("parksHere", TrueFalseUnknown.TRUE);
                  setCurrentStep("alreadyInGroup");
                }}
              >
                {t("yes")}
              </button>
              <button
                onClick={() => {
                  answerQuestion("parksHere", TrueFalseUnknown.FALSE);
                  setCurrentStep(
                    answers.livesHere === TrueFalseUnknown.FALSE &&
                      answers.ownsApartment === TrueFalseUnknown.FALSE
                      ? "resultNotEligible"
                      : "alreadyInGroup"
                  );
                }}
              >
                {t("no")}
              </button>
            </div>
          </section>

          <section
            className={styles.step}
            hidden={currentStep !== "alreadyInGroup"}
          >
            <p className={styles.question}>
              {t("areYouAlreadyInFacebookGroup")}
            </p>
            <div className={styles.horizontal}>
              <button
                onClick={() => {
                  answerQuestion("alreadyInGroup", TrueFalseUnknown.TRUE);
                  setCurrentStep("resultAlreadyJoined");
                }}
              >
                {t("yes")}
              </button>
              <button
                onClick={() => {
                  answerQuestion("alreadyInGroup", TrueFalseUnknown.FALSE);
                  setCurrentStep("wouldLikeToJoin");
                }}
              >
                {t("no")}
              </button>
            </div>
          </section>

          <section
            className={styles.step}
            hidden={currentStep !== "wouldLikeToJoin"}
          >
            <p className={styles.question}>{t("wouldYouLikeToJoin")}</p>
            <div className={styles.horizontal}>
              <button
                onClick={() => {
                  answerQuestion("wouldLikeToJoin", TrueFalseUnknown.TRUE);
                  setCurrentStep("resultJoinGroup");
                }}
              >
                {t("yes")}
              </button>
              <button
                onClick={() => {
                  answerQuestion("wouldLikeToJoin", TrueFalseUnknown.FALSE);
                  setCurrentStep("resultWouldntLikeToJoin");
                }}
              >
                {t("no")}
              </button>
            </div>
          </section>

          <section
            className={styles.step}
            hidden={currentStep !== "resultNotEligible"}
          >
            <p className={styles.hugeEmoji}>🙊</p>
            <p className="align-center">
              {t("theFacebookGroupIsForResidentsOnly")}
            </p>
            <LinkButton
              to="/"
              highlight
              fullWidth
              style={{
                margin: "0 var(--spacing-large)",
                marginTop: "calc(2 * var(--spacing-large))",
              }}
            >
              {t("home")}
            </LinkButton>
          </section>

          <section
            className={styles.step}
            hidden={currentStep !== "resultAlreadyJoined"}
          >
            <p className={styles.hugeEmoji}>🥰</p>
            <p className="align-center">{t("itsGreatToHaveYou")}</p>
            <LinkButton
              to="#"
              highlight
              fullWidth
              onClick={() => {
                registerEvent(
                  "Clicked Join Facebook Group button",
                  "resultAlreadyJoined"
                )?.then(() => {
                  window.location.href =
                    "https://facebook.com/groups/centropolitan";
                });
              }}
              style={{
                margin: "0 var(--spacing-large)",
                marginTop: "calc(2 * var(--spacing-large))",
              }}
            >
              {t("visitFacebookGroup")}
            </LinkButton>
            <LinkButton
              to="/"
              fullWidth
              style={{
                margin: "0 var(--spacing-large)",
                marginTop: "var(--spacing)",
              }}
            >
              {t("home")}
            </LinkButton>
          </section>

          <section
            className={styles.step}
            hidden={currentStep !== "resultJoinGroup"}
          >
            <p className={styles.hugeEmoji}>😍</p>
            <p className="align-center">{t("toJoinTheGroupClick")}</p>
            <LinkButton
              highlight
              fullWidth
              to="#"
              onClick={() => {
                logEventToAnalytics("join_fb_group");
                registerEvent(
                  "Clicked Join Facebook Group button",
                  "resultJoinGroup"
                )?.then(() => {
                  window.location.href =
                    "https://facebook.com/groups/centropolitan";
                });
              }}
              style={{
                margin: "var(--spacing-large)",
                marginTop: "calc(2 * var(--spacing-large))",
              }}
            >
              {t("joinFacebookGroup")}
            </LinkButton>
          </section>

          <section
            className={styles.step}
            hidden={currentStep !== "resultWouldntLikeToJoin"}
          >
            <p className={styles.hugeEmoji}>💔</p>
            <p className="align-center">
              {t("ifYouChangeYourMindYoureAlwaysWelcomeToJoin")}
            </p>
            <LinkButton
              highlight
              fullWidth
              to="/"
              style={{
                margin: "0 var(--spacing-large)",
                marginTop: "calc(2 * var(--spacing-large))",
              }}
            >
              {t("home")}
            </LinkButton>
            <LinkButton
              to="#"
              fullWidth
              onClick={() => {
                registerEvent(
                  "Clicked Join Facebook Group button",
                  "resultWouldntLikeToJoin"
                )?.then(() => {
                  window.location.href =
                    "https://facebook.com/groups/centropolitan";
                });
              }}
              style={{
                margin: "0 var(--spacing-large)",
                marginTop: "var(--spacing)",
              }}
            >
              {t("joinFacebookGroup")}
            </LinkButton>
          </section>
        </div>
      </article>
    </Page>
  );
};
