import classnames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./Page.module.css";

interface PageProps {
  displayNav?: boolean;
  centered?: boolean;
}

export const Page: React.FC<PageProps> = ({
  displayNav = true,
  centered = false,
  children,
}) => {
  const { t } = useTranslation();

  const contentClassName = classnames({
    [styles.content]: true,
    [styles.centered]: centered,
  });

  return (
    <div className={styles.page}>
      {displayNav ? (
        <nav>
          <Link to="/">&lt; {t("home")}</Link>
        </nav>
      ) : (
        <></>
      )}
      <div className={contentClassName}>{children}</div>
    </div>
  );
};
