import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "../../components/Page";
import { useEventRegistration } from "../../hooks/useEventRegistration";

export const Instagram = () => {
  const { t } = useTranslation();
  const { registerEvent } = useEventRegistration();

  useEffect(() => {
    registerEvent("Visited Instagram page");

    const timeout = setTimeout(async () => {
      await registerEvent("Redirecting to Instagram");
      try {
        await registerEvent("Redirecting to Instagram app");
        window.location.replace("instagram://user?username=centropolitan");
      } catch (e) {
        await registerEvent("Redirecting to Instagram web");
        window.location.replace("https://www.instagram.com/centropolitan");
      }
    }, 1500);

    return () => clearTimeout(timeout);
  }, [registerEvent]);

  return (
    <Page centered>
      <article className="vertical-rhythm" style={{ textAlign: "center" }}>
        <h2 className="h2">{t("redirectingToInstagram")}</h2>
      </article>
    </Page>
  );
};
