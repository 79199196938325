import createPersistedState from "use-persisted-state";
import { v4 as uuidv4 } from "uuid";

export const useDeviceId = () => {
  const useDeviceIdState = createPersistedState<string>("did");
  const [deviceId, setDeviceId] = useDeviceIdState("");

  try {
    if (!deviceId) {
      const newDeviceId = uuidv4().substring(0, 8);
      // console.log(`New DID generated: ${newDeviceId}.`);
      setDeviceId(newDeviceId);
      // TODO: Report DID.
    } else if (deviceId.length > 8) {
      setDeviceId(deviceId.substring(0, 8));
    }
    // console.log(`DID found: ${deviceId}.`);
  } catch (e) {
    // console.error("DID error");
  }

  return { deviceId };
};
