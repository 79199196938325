import classnames from "classnames";
import React, { useContext } from "react";
import { AppContext } from "../../AppContextProvider";
import styles from "./Footer.module.css";

export const Footer = () => {
  const { isOnline } = useContext(AppContext);

  const onlineIndicatorStyles = classnames({
    [styles.onlineIndicator]: true,
    [styles.offline]: !isOnline,
  });

  return (
    <footer className={styles.footer}>
      &copy; {new Date().getFullYear()} || {window.version} ||{" "}
      <span className={onlineIndicatorStyles} />
    </footer>
  );
};
