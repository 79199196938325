import React from "react";
import { Page } from "../../components/Page";
import { useNotifications } from "../../hooks/useNotifications";

export const Notifications = () => {
  const { addNotification, removeNotification, notifications } =
    useNotifications();

  return (
    <Page>
      <h2 className="h2">Notifications</h2>
      <section>
        <button
          onClick={() => addNotification(new Date().toISOString(), "message")}
        >
          Add notification
        </button>
        <ul>
          {notifications.map((n) => (
            <li key={n.id}>
              <div>{n.id}</div>
              <div>{n.title}</div>
              <div>{n.message}</div>
              <div>{n.type}</div>
              <button onClick={() => removeNotification(n.id)}>
                Remove notification
              </button>
              <hr />
            </li>
          ))}
        </ul>
      </section>
    </Page>
  );
};
