import React from "react";
import { useTranslation } from "react-i18next";
import { useEventRegistration } from "../../hooks/useEventRegistration";
import styles from "./LanguageSelector.module.css";

export const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const { registerEvent } = useEventRegistration();

  const toggleLanguage = () => {
    const newLanguage = i18n.language === "hu" ? "en" : "hu";
    i18n.changeLanguage(i18n.language === "hu" ? "en" : "hu");
    registerEvent(`Changed language to ${newLanguage}`);
  };

  return (
    <div className={styles.languageSelector} onClick={toggleLanguage}>
      {i18n.language.substring(0, 2).toUpperCase()}
    </div>
  );
};
