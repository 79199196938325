import React from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";
import styles from "./Layout.module.css";

export const Layout = () => {
  return (
    <div className={styles.app}>
      <Header />
      <div className={styles.page}>
        <main className={styles.main}>
          <Outlet />
        </main>
        <Footer />
      </div>
    </div>
  );
};
