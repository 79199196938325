import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LinkButton } from "../../components/LinkButton";
import { Page } from "../../components/Page";
import { DayType } from "../../constants";
import { useEventRegistration } from "../../hooks/useEventRegistration";
import { useNoise } from "../../hooks/useNoise";
import { getDayType, isToday } from "../../utils";
import styles from "./Noise.module.css";

export const Noise = () => {
  const { t, i18n } = useTranslation();
  const { isAllowed, nextChange } = useNoise();

  const { registerEvent } = useEventRegistration();

  useEffect(() => {
    registerEvent("Visited Noise page");
  }, [registerEvent]);

  const dayType = getDayType(new Date());

  return (
    <Page centered>
      <article className="vertical-rhythm" style={{ textAlign: "center" }}>
        <h2 className="h2">{t("canIMakeNoiseNow")}</h2>
        {isAllowed ? (
          <div className={styles.answer}>
            <div className={styles.icon}>✅</div>
            <div>
              {dayType === DayType.NATIONAL_HOLIDAY
                ? t("todayIsNationalHoliday")
                : ""}{" "}
              {dayType === DayType.SWAPPED_RESTDAY
                ? t("todayIsSwappedRestday")
                : ""}{" "}
              {dayType === DayType.SWAPPED_WORKDAY
                ? t("todayIsSwappedWorkday")
                : ""}{" "}
              {t("noiseAllowed")}{" "}
              {Intl.DateTimeFormat(i18n.language, {
                weekday: isToday(nextChange) ? undefined : "long",
                hour: "numeric",
                minute: "numeric",
                dayPeriod: "short",
              }).format(nextChange)}
              {t("noiseUntilPostfix")}
            </div>
          </div>
        ) : (
          <div className={styles.answer}>
            <div className={styles.icon}>❌</div>
            <div>
              {dayType === DayType.NATIONAL_HOLIDAY
                ? t("todayIsNationalHoliday")
                : ""}{" "}
              {dayType === DayType.SWAPPED_RESTDAY
                ? t("todayIsSwappedRestday")
                : ""}{" "}
              {dayType === DayType.SWAPPED_WORKDAY
                ? t("todayIsSwappedWorkday")
                : ""}{" "}
              {t("noiseProhibited")}{" "}
              {Intl.DateTimeFormat(i18n.language, {
                weekday: isToday(nextChange) ? undefined : "long",
                hour: "numeric",
                minute: "numeric",
                dayPeriod: "short",
              }).format(nextChange)}
              {t("noiseUntilPostfix")}
            </div>
          </div>
        )}
        <div>{t("noiseReferToHouseRules")} </div>
        <LinkButton highlight to="/">
          {t("home")}
        </LinkButton>
      </article>
    </Page>
  );
};
