import { collectionGroup, getDocs, query } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContextProvider";
import { Page } from "../../components/Page";
import { firestore, logEventToAnalytics } from "../../firebaseApp";
import { useEventRegistration } from "../../hooks/useEventRegistration";
import { isLocalhost } from "../../utils";
import styles from "./Admin.module.css";

export const Admin = () => {
  const [showAdmin, setShowAdmin] = useState(isLocalhost());
  const [passwordHash, setPasswordHash] = useState("");
  const { registerEvent, migrateEvent } = useEventRegistration();
  const { deviceId, sessionId, ref, isUpdateAvailable } =
    useContext(AppContext);

  useEffect(() => {
    if (showAdmin) {
      registerEvent("Accessed Admin UI");
      logEventToAnalytics("visit_admin");
    }
  }, [registerEvent, showAdmin]);

  useEffect(() => {
    setShowAdmin(passwordHash === "gumimaci" || isLocalhost());
  }, [passwordHash]);

  const getRegistrations = () => {
    navigator.serviceWorker
      .getRegistrations()
      .then((list) => console.log(list));
  };

  const getServiceWorker = () => {
    console.log(navigator.serviceWorker.controller);
  };

  const testNotification = () => {};

  // eslint-disable-next-line
  const migrateDb = async () => {
    const deviceIds = [
      "3beeba80-6906-4340-82ae-f193daeb2262",
      "09700258-3a71-4f0e-8506-18d402c8cb25",
      "72241fae-5e60-4874-8860-32426ac0179d",
      "994a1fd9-cd6a-4d9a-b364-0ed50661ac0f",
      "e93cc407-eab1-4c3c-b66c-ccb5fa7aca8c",
      "ba06165b-92a8-494e-a179-4010aa5af83c",
      "d3f4a4cf-ea50-4fac-bb09-f08c9066bc78",
      "23b4835e-798b-44fa-a0ee-937e852cadbf",
      "2c39978f-e79b-424c-af13-0b31aa3d048f",
      "88963aa7-d76e-4624-8ab9-90dd0400a4de",
      "89d96661-643b-4053-9417-115c9240cfa1",
      "63406706-a663-4e45-9201-9852ed34844d",
      "3f9b95d5-2dc1-4927-8f25-f49ebbf3cf88",
      "d4a515f3-5e47-460d-bf9b-153458ed3e13",
      "ec892257-7d0e-4eaf-8004-34c8df88d10d",
      "43558891-3a6a-4965-b1f1-6a0e1d08189e",
      "20be1ace-8909-4359-9ac9-28a052da8eed",
      "df2e0368-8d54-4175-9874-4111571e720c",
      "2010c078-2071-4c84-94c5-dc37143bc793",
      "4fd91c57-dae0-433c-b0ac-e06c3fd6c514",
      "66dda73e-5587-4ed1-bcc2-e01d9ff9db94",
      "77ad607b-2bf1-4f07-b6b4-b269e667d5c3",
      "55884d12-eca3-4354-a3eb-108a2a590a34",
      "bdd8a666-a10d-4088-8d09-c2fc126acfd9",
      "3beeba80",
      "09700258",
      "72241fae",
      "994a1fd9",
      "e93cc407",
      "ba06165b",
      "d3f4a4cf",
      "23b4835e",
      "2c39978f",
      "88963aa7",
      "89d96661",
      "63406706",
      "3f9b95d5",
      "d4a515f3",
      "ec892257",
      "43558891",
      "20be1ace",
      "df2e0368",
      "2010c078",
      "4fd91c57",
      "66dda73e",
      "77ad607b",
      "55884d12",
      "bdd8a666",
    ];

    deviceIds.forEach(async (did) => {
      const q = query(collectionGroup(firestore, did));
      console.log("querying...");
      const snapshot = await getDocs(q);
      snapshot.forEach((doc) => {
        console.log(`migrating document ${doc.id}`);
        migrateEvent(doc.data());
        console.log("migration done");
      });
      console.log("all done");
    });
  };

  // eslint-disable-next-line
  const m = async () => {
    const q = query(collectionGroup(firestore, "events"));
    console.log("querying...");
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
      console.log(`migrating document ${doc.id}`);
      migrateEvent(doc.data());
      console.log("migration done");
    });
    console.log("all done");
  };

  // eslint-disable-next-line
  const queryData = async () => {
    const q = query(collectionGroup(firestore, "events"));
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
      const data = doc.data();
      if (data.ref === "f6c820") {
        console.log(data.deviceId);
        console.log(data.timestamp.toDate());
        console.log(data.ref);
      }
    });
  };

  return (
    <Page centered={!showAdmin}>
      {showAdmin ? (
        <section className="vertical-rhythm">
          {/* <button onClick={queryData}>Query</button> */}
          <h2 className="h2">Admin</h2>
          <hr />
          <h3 className="h3">Debug Tools</h3>
          <div>Device ID:</div>
          <div>{deviceId}</div>
          <hr />
          <div>Session ID:</div>
          <div>{sessionId}</div>
          <hr />
          <div>Ref:</div>
          <div>{ref}</div>
          <hr />
          <h3 className="h3">Service Worker</h3>
          <div>
            <button
              onClick={() =>
                navigator.serviceWorker
                  .getRegistration()
                  .then((registration) => registration?.update())
              }
            >
              Update SW
            </button>
          </div>
          <div>Update available: {String(isUpdateAvailable)}</div>
          <div>Service worker state:</div>
          <div>{navigator?.serviceWorker?.controller?.state || "(none)"}</div>
          <div>
            <button onClick={getRegistrations}>getRegistrations()</button>
          </div>
          <div>
            <button onClick={getServiceWorker}>getServiceWorker()</button>
          </div>
          <div>
            <button onClick={testNotification}>Test notification (TODO)</button>
          </div>
        </section>
      ) : (
        <input
          type="password"
          onChange={(e) => setPasswordHash(e.target.value)}
          className={styles.passwordInput}
        ></input>
      )}
    </Page>
  );
};
