import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Page } from "../../components/Page";
import { useEventRegistration } from "../../hooks/useEventRegistration";

export const About = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [count, setCount] = useState<number>(0);
  const { registerEvent } = useEventRegistration();

  useEffect(() => {
    registerEvent("Visited About page");
  }, [registerEvent]);

  const handleClick = () => {
    setCount(() => {
      return count + 1;
    });
  };

  useEffect(() => {
    if (count === 10) {
      navigate("/admin");
    }
  }, [count, navigate]);

  return (
    <Page>
      <article className="vertical-rhythm">
        <h2 className="h2" onClick={handleClick}>
          {t("aboutThisApp")}
        </h2>
        <hr />

        <h3 className="h3">{t("technicalDetails")}</h3>
        <p>{t("comingSoon")}</p>
        <hr />

        <h3 className="h3">{t("contact")}</h3>
        <p>
          <a href="mailto:developer@cordia.homes?subject=Centropolitan app">
            {t("clickHereToContactDev")}{" "}
          </a>
        </p>
        <hr />

        <h3 className="h3">{t("disclaimer")}</h3>
        <p>{t("disclaimerText")}</p>
      </article>
    </Page>
  );
};
