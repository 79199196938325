import classnames from "classnames";
import React, { useEffect, useState } from "react";
import styles from "./Snackbar.module.css";

interface SnackbarProps {
  id: string;
  isActive: boolean;
  message: string;
  title: string;
  onCloseClick?: (id: string) => void;
}

export const Snackbar: React.FC<SnackbarProps> = ({
  id,
  isActive,
  message,
  title,
  onCloseClick,
}) => {
  const [active, setActive] = useState(false);
  const [display, setDisplay] = useState(true);

  useEffect(() => {
    show();
  }, []);

  const show = () => {
    setDisplay(true);
    setActive(true);
  };

  const hide = () => {
    setActive(false);
    setTimeout(() => setTimeout(() => setDisplay(false), 300));
  };

  const handleCloseClick = () => {
    setActive(false);
    onCloseClick && setTimeout(() => onCloseClick(id), 300);
  };

  const classes = classnames({
    [styles.snackbar]: true,
    [styles.fadeIn]: active,
    [styles.fadeOut]: !active,
    [styles.displayNone]: !display,
  });

  return (
    <div className={classes} onClick={() => hide()}>
      <div className={styles.message}>{title}</div>
      <button className={styles.closeButton} onClick={handleCloseClick}>
        ╳
      </button>
    </div>
  );
};
